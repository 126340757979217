'use strict'

let window_height = window.innerHeight
let header = document.querySelector('.Header')
const body = document.querySelector('body')
let ua = navigator.userAgent
const sp_width = 769
let isiPhone = ua.indexOf('iPhone') > -1
let isiPad = ua.indexOf('iPad') > -1
let isAndroid = ua.indexOf('Android') > -1 && ua.indexOf('Mobile') > -1
let isAndroidTabvar = ua.indexOf('android') > -1 && ua.indexOf('Mobile') == -1

// スマホ＆タブレット
if (isiPhone || isiPad || isAndroid || isAndroidTabvar) {
  // spFullscreen();
  window.addEventListener('DOMContentLoaded', () => {
    spFullscreenCommon()
    // if(!body.dataset.cookie){
      // スクロール禁止(SP)
      document.addEventListener('touchmove', noScroll, { passive: false });
      // スクロール禁止(PC)
      document.addEventListener('mousewheel', noScroll, { passive: false });
    // }
  })

  // window.addEventListener('scroll', function() {
  //   spFullscreenCommon()
  // })
} else {
}

window.addEventListener('DOMContentLoaded', () => {
  $('html,body').animate({ scrollTop: 0 }, '1');
})

window.addEventListener('load', () => {

  headerBackground()

  // slick オプション
  var option = {
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 2000,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    dots: false,
    fade: true,
    arrows: false,
    infinite: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    waitForAnimate: false
  }
  $('#js-slide').slick(option)

  let windowWidth = window.innerWidth
  if(windowWidth > sp_width){
    $('._News__content dd').trunk8({
      lines: 1,   //行数を指定、未設定の場合１行
      fill: '⋯' //省略時の末尾に付与させる文字を指定（無ければ「…」）
    });
  } else {
    $('._News__content dd').trunk8({
      lines: 2,   //行数を指定、未設定の場合１行
      fill: '⋯' //省略時の末尾に付与させる文字を指定（無ければ「…」）
    });
  }


  if(body.dataset.cookie){
    $("._Intoro").hide()
  }

  window.setTimeout(function(){
    // スクロール禁止を解除(SP)
    document.removeEventListener('touchmove', noScroll, { passive: false });
    // スクロール禁止を解除(PC)
    document.removeEventListener('mousewheel', noScroll, { passive: false });
}, 9000);
});

// ----------  スクロール処理  ----------
window.addEventListener('scroll', () => {

  headerBackground()

})

// ----------  スクロール処理  ----------
window.addEventListener('resize', () => {

  let windowWidth = window.innerWidth
  if(windowWidth > sp_width){
    $('._News__content dd').trunk8({
      lines: 1,   //行数を指定、未設定の場合１行
      fill: '⋯' //省略時の末尾に付与させる文字を指定（無ければ「…」）
    });
  } else {
    $('._News__content dd').trunk8({
      lines: 2,   //行数を指定、未設定の場合１行
      fill: '⋯' //省略時の末尾に付与させる文字を指定（無ければ「…」）
    });
  }

})


// ---------- ヘッダーの背景有無 ----------
const headerBackground = ()=>{
  let scrollTop = $(window).scrollTop()
  let heroHeight = document.querySelector('._Hero').clientHeight

  if(scrollTop > heroHeight) {
    header.classList.remove('is-noBackground')
  } else {
    header.classList.add('is-noBackground')
  }
}


// ---------- スマホの場合のフルスクリーンの高さ調整 ----------
function spFullscreenCommon() {
  const window_height = window.innerHeight
  // スライド画像の高さ
  const mainVisual = document.querySelector('._Hero')
  const intro = document.querySelector('._Intro')
  mainVisual.style.height = window_height + 'px'
  intro.style.height = window_height + 'px'
}


function noScroll(event) {
  event.preventDefault();
}
// スクロール禁止(SP)
// document.addEventListener('touchmove', noScroll, { passive: false });
// スクロール禁止(PC)
// document.addEventListener('mousewheel', noScroll, { passive: false });
// スクロール禁止を解除(SP)
// document.removeEventListener('touchmove', noScroll, { passive: false });
// スクロール禁止を解除(PC)
// document.removeEventListener('mousewheel', noScroll, { passive: false });